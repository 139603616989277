// 通知配置  notificationConfigAPI
// import request from '@/utils/request'
import {
    get,
    // post,
    // postForm,
    postJson,
} from "./http/http";

const httpServer = {
    // 保存通知配置
    saveNotificationConfig(params) {
        return postJson("/notice/config-template/save", params);
    },
    // 通知配置列表
    getNotificationConfigTable(params) {
        return postJson(`/notice/config-template/table`, params);
    },
    // 消息通知未读数量
    getNoticeCount() {
        return get(`/notice/record/count`);
    },
    // 通知列表
    getNotificationsList(params) {
        return postJson(`/notice/record/list`, params);
    },
    // 获取通知人员配置
    getNotificationPersonConfig() {
        return get(`/notice/config-person/list`);
    },
    // 获取通知人员配置
    saveNotificationPersonConfig(params) {
        return postJson(`/notice/config-person/save`, params);
    },
    // 单个消息标记为已读
    readNotification(id) {
        return get(`/notice/record/updateRead/${id}`);
    },
    // 全部消息标记为已读
    readAllNotification() {
        return get(`/notice/record/updateAllRead`);
    },

};

export default httpServer;
