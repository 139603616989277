// 通知配置
<template>

    <div class="notificationConfig baseBg">
        <div class="listBox mainWhiteBg">
            <div class="tableBox">
                <div class="greySearchBar">
                    <el-form :model="searchForm"
                             ref="searchForm"
                             :rules="searchFormRules"
                             :inline="true">
                        <el-form-item label="通知类型"
                                      prop="noticeType">
                            <el-input v-model="searchForm.noticeType"
                                      placeholder="请输入通知类型"
                                      clearable></el-input>
                        </el-form-item>
                        <el-button type="primary"
                                   @click="getList(1)">搜索</el-button>
                        <el-button @click="resetSearchForm">清除</el-button>
                        <el-button type="success"
                                   @click="openpopNotificationPersonConfig"
                                   class="flr">通知人员配置</el-button>
                    </el-form>
                </div>

                <div class="tableBox">
                    <el-table :data="tableData.data"
                              border
                              :height="tableHeight"
                              stripe>
                        <el-table-column type="index"
                                         label="序号"
                                         width="60">
                        </el-table-column>
                        <el-table-column prop="noticeType"
                                         label="通知类型"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="sysFlag"
                                         label="通知主体"
                                         width="auto"
                                         :min-width="100">
                            <template slot-scope="scope">
                                {{scope.row.sysFlag ? "系统" : "个人"}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="content"
                                         label="内容配置"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="url"
                                         label="web跳转地址"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="miniAppUrl"
                                         label="小程序跳转地址"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="description"
                                         label="描述"
                                         align="left"
                                         min-width="auto"
                                         width="220">
                            <template slot-scope="scope">
                                <el-tooltip class="item"
                                            effect="dark"
                                            :content="scope.row.description">
                                    <p class="ellipsis">{{scope.row.description || "暂无"}}</p>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="id"
                                         label="操作"
                                         min-width="100">
                            <template slot-scope="scope">
                                <el-link type="primary"
                                         @click="openpopEditNotificationConfig(scope.row)">编辑</el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <Pagination :page="searchForm.page"
                                :limit="searchForm.size"
                                :total="pageTotal"
                                @pagination="paginationChange" />

                </div>

            </div>
        </div>
        <!-- 通知人员配置 -->
        <pop-notification-person-config v-if="popNotificationPersonConfigVisible"
                                        @close="closepopNotificationPersonConfig"></pop-notification-person-config>
        <!-- 编辑通知配置 -->
        <pop-edit-notification-config v-if="popEditNotificationConfigVisible"
                                      :rowData="editRow"
                                      @update="getList"
                                      @close="closepopEditNotificationConfig"></pop-edit-notification-config>
    </div>

</template>

<script>
import PopEditNotificationConfig from "./components/notificationConfig/popEditNotificationConfig";
import PopNotificationPersonConfig from "./components/notificationConfig/popNotificationPersonConfig";
import Pagination from "@/components/Pagination"; // 分页
import { Loading } from "element-ui";
import API from "@/api/notificationConfigAPI.js";

export default {
    name: "notificationConfig",

    props: {},

    components: {
        PopEditNotificationConfig,
        PopNotificationPersonConfig,
        Pagination,
    },

    data() {
        return {
            searchForm: {
                noticeType: "", // 通知类型
                page: 1,
                size: 20,
            },
            searchFormRules: {},
            pageTotal: 0,
            tableData: {
                data: [],
            },
            editRow: null, // 当前操作行
            popNotificationPersonConfigVisible: false, // 通知人员配置弹窗显示
            popEditNotificationConfigVisible: false, // 编辑通知配置弹窗显示
        };
    },

    created() {},

    mounted() {
        // 获取列表
        this.getList();
    },

    methods: {
        // 获取列表
        getList(page) {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            let type = null;
            this.searchForm.type = type;
            let data = Object.assign({}, this.searchForm, {
                page: page || this.searchForm.page,
            });
            API.getNotificationConfigTable(data)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 分页改变
        paginationChange(e) {
            this.searchForm.size = e.size;
            this.searchForm.page = e.page;
            // 获取列表
            this.getList();
        },
        // 清空表单
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    // 查询列表
                    this.getList();
                }
            });
        },
        // 通知人员配置 打开弹框
        openpopNotificationPersonConfig() {
            this.popNotificationPersonConfigVisible = true;
        },
        // 通知人员配置 关闭弹框
        closepopNotificationPersonConfig() {
            this.popNotificationPersonConfigVisible = false;
        },
        // 编辑通知配置 打开弹框
        openpopEditNotificationConfig(row) {
            this.editRow = row || null;
            this.popEditNotificationConfigVisible = true;
        },
        // 编辑通知配置 关闭弹框
        closepopEditNotificationConfig() {
            this.editRow = null;
            this.popEditNotificationConfigVisible = false;
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 220 });
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
.pagination-container {
    margin-top: 0;
}
</style>
