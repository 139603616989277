// 根据公司部门树选择人员
<template>

    <div class="selectPersonInDeptTree">
        <el-cascader :value="value"
                     ref="selectPerson"
                     :placeholder="placeholder || '请选择处理人员'"
                     :show-all-levels="false"
                     :options="options"
                     :filterable="filterable"
                     @change="change($event)"
                     :props="{ emitPath: false,
                                label:'label',
                                multiple: multiple,
                                value: 'id' }"
                     clearable></el-cascader>
    </div>

</template>

<script>
import { Loading } from "element-ui";
import selectOptsAPI from "@/api/selectOpts.js";
export default {
    name: "selectPersonInDeptTree",

    props: {
        value: String,
        // 提示语
        placeholder: {
            type: String,
        },
        // 是否可以多选，默认为false
        multiple: {
            type: Boolean,
            default: false,
        },
        // 是否可以搜索，默认为false
        filterable: {
            type: Boolean,
            default: false,
        },
    },

    components: {},

    data() {
        return {
            options: [],
        };
    },

    created() {},

    mounted() {
        // 公司下的联系人列表
        this.getPersonInDeptTree();
    },

    methods: {
        change: function (val) {
            this.$emit("input", val);
            this.$emit("change");
        },
        // 公司下的联系人列表
        getPersonInDeptTree() {
            let loading = Loading.service({
                target: document.querySelector(".selectPersonInDeptTree"),
            });
            return selectOptsAPI
                .getPersonInDeptTree()
                .then((res) => {
                    this.options = res.content;
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {
        // 选中的节点数据
        selectNode() {
            let opts = this.$refs["selectPerson"].getCheckedNodes();
            opts = opts.filter((item) => {
                return !item.hasChildren;
            });
            opts = opts.map((item) => {
                return {
                    label: item.label,
                    value: item.value,
                };
            });
            return opts;
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
// .selectPersonInDeptTree{

// }
</style>
