// 编辑通知配置
// popEditNotificationConfig
<template>

    <div class="popEditNotificationConfig">
        <el-dialog title="编辑通知配置"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   width="850px"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     :rules="formRules">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="通知类型"
                                      prop="noticeType">
                            <el-input v-model="form.noticeType"
                                      placeholder="请输入通知类型"
                                      disabled
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="通知主体"
                                      prop="sysFlag">
                            <el-select v-model="form.sysFlag"
                                       placeholder="请选择通知主体"
                                       clearable>
                                <el-option label="系统"
                                           :value="true"></el-option>
                                <el-option label="个人"
                                           :value="false"></el-option>
                            </el-select>

                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="内容配置"
                                      prop="content">
                            <el-input v-model="form.content"
                                      type="textarea"
                                      :rows="5"
                                      placeholder="请输入内容配置"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="web跳转地址"
                                      prop="url">
                            <el-input v-model="form.url"
                                      type="textarea"
                                      :rows="5"
                                      placeholder="请输入web跳转地址"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="小程序跳转地址"
                                      prop="miniAppUrl">
                            <el-input v-model="form.miniAppUrl"
                                      type="textarea"
                                      :rows="5"
                                      placeholder="请输入小程序跳转地址"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="描述"
                                      prop="description">
                            <el-input v-model="form.description"
                                      type="textarea"
                                      :rows="5"
                                      placeholder="请输入描述"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="save">保存</el-button>
                </div>
            </el-form>

        </el-dialog>

    </div>
</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/notificationConfigAPI.js";
export default {
    name: "popEditNotificationConfig",

    props: {
        // 编辑的行信息
        rowData: {
            type: Object,
            default: null,
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,
            form: {
                noticeType: "", // 通知类型
                sysFlag: "", // 通知主体
                content: "", // 内容配置
                url: "", // 跳转地址
                miniAppUrl: "", // 小程序跳转地址
                description: "", // 描述
            },
            formRules: {},
        };
    },

    created() {},

    mounted() {
        this.form = this.rowData;
    },

    methods: {
        // 保存
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = Loading.service({
                        target: document.querySelector(".el-dialog"),
                    });
                    API.saveNotificationConfig(this.form)
                        .then(() => {
                            loading.close();
                            this.$message({
                                message: "操作成功！",
                                type: "success",
                            });
                            this.$emit("update");
                            this.close();
                        })
                        .catch(() => {
                            loading.close();
                        });
                }
            });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>