// 通知人员配置
// popNotificationPersonConfig
<template>

    <div class="popNotificationPersonConfig">
        <el-dialog title="通知人员配置"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   width="850px"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     :inline="false">
                <div class="popItemBox"
                     v-for="(item,idx) in form"
                     :key="idx">
                    <div class="title">{{item.type.text}}</div>
                    <el-row :gutter="60"
                            class="infoBox">
                        <el-col :span="8"
                                v-for="(it,i) in item.personDeptList"
                                :key="i">
                            <el-form-item :label="it.deptName"
                                          prop="userIdList">
                                <select-person-in-dept-tree v-model="it.userIdList"
                                                            :multiple="true"
                                                            :filterable="true"
                                                            placeholder="请选择"
                                                            clearable></select-person-in-dept-tree>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="save">保存</el-button>
                </div>
            </el-form>

        </el-dialog>

    </div>
</template>

<script>
import SelectPersonInDeptTree from "../../../../modleComponents/person/selectPersonInDeptTree";
import { Loading } from "element-ui";
import API from "@/api/notificationConfigAPI.js";
export default {
    name: "popNotificationPersonConfig",

    props: {},

    components: {
        SelectPersonInDeptTree,
    },

    data() {
        return {
            dialogVisible: true,
            form: [],
            formRules: {},
        };
    },

    created() {},

    mounted() {
        // 获取通知人员配置
        this.getNotificationPersonConfig();
    },

    methods: {
        // 获取通知人员配置
        getNotificationPersonConfig() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.getNotificationPersonConfig()
                .then((res) => {
                    loading.close();
                    this.form = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 保存配置
        save() {
            // this.$refs.form.validate((valid) => {
            // if (valid) {
            let loading = Loading.service({
                target: document.querySelector(".el-dialog"),
            });
            let data = JSON.parse(JSON.stringify(this.form));
            data.forEach(item=>{
                item.type = item.type.name
            })
            API.saveNotificationPersonConfig(data)
                .then(() => {
                    loading.close();
                    this.$message({ message: "操作成功！", type: "success" });
                    this.close();
                })
                .catch(() => {
                    loading.close();
                });
            // }
            // });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.popItemBox .infoBox {
    padding: 14px 20px 0;
}
</style>